<template>
  <HourglassLoader :index="1050" :loading="loading">
    <section>
      <div class="d-flex justify-content-between align-items-center py-3">
        <div class="d-block">
          <h2 class="h4">Sell Gift Card Transaction Details</h2>
          <!--          <p class="mb-0">Below are Sell Gift Card Transactions/Request</p>-->
        </div>

        <div class="btn-toolbar">
          <button type="button" class="btn btn-sm btn-outline-primary" @click="RefreshData">
            Reload
          </button>
        </div>
      </div>
      <div v-show="data && data.status === 'SUBMITTED'" class="table-settings mb-4">
        <div class="d-flex align-items-center justify-content-between">
          <div class>
            <button type="button" class="btn btn-sm btn-success" data-bs-toggle="offcanvas"
              data-bs-target="#approveCanvas" aria-controls="approveCanvas">
              Approve Transaction
            </button>
          </div>
          <div class>
            <button type="button" class="btn btn-sm btn-danger text-nowrap" @click="
              RequestConfirmation(
                'Do you really want to decline this transaction?',
                () => {
                  RequestConfirmation(
                    'Sure to decline this transaction? This is an irreversible action',
                    () => {
                      sureConfirmDecline = true;
                    }
                  );
                }
              )
              ">
              Decline Transaction
            </button>
          </div>
        </div>
      </div>
      <div v-if="data" class="row gy-5">
        <div class="col-12">
          <div class="card card-body border-light shadow-sm">
            <div class="text-center">
              <h4 class="text-gray">Reference</h4>
              <h5 class="fw-bolder">{{ data.reference }}</h5>
            </div>

            <div class="row justify-content-center text-center py-3">
              <div class="col-6 col-md-4">
                <h4 class="text-gray">Status</h4>
                <h6>{{ data.status }}</h6>
              </div>
              <div class="col-6 col-md-4">
                <h4 class="text-gray">Rate</h4>
                <h6>{{ data.rate }}</h6>
              </div>
              <div class="col-6 col-md-4">
                <h4 class="text-gray">Amount</h4>
                <h6>{{ data.amount }}</h6>
              </div>
              <div class="col-6 col-md-4">
                <h4 class="text-gray">Payout amount</h4>
                <h6>
                  {{ data.CreditCurrency.code }}
                  {{
                    new Intl.NumberFormat("en-NG", {
                      style: "currency",
                      currency: "NGN",
                    }).format(data.payout_amount)
                  }}
                </h6>
              </div>
            </div>

            <div class="row text-center py-3">
              <div class="col-12 col-md-4">
                <h4 class="text-gray">UserId</h4>
                <h6>{{ data.User.id }}</h6>
              </div>
              <div class="col-12 col-md-4">
                <h4 class="text-gray">Email</h4>
                <router-link :to="`/users/${data.User.id}`">
                  <h6>{{ data.User.email }}</h6>
                </router-link>
                <!-- <h6>{{ data.User.email }}</h6> -->
              </div>
              <div class="col-12 col-md-4">
                <h4 class="text-gray">Name</h4>
                <router-link :to="`/users/${data.User.id}`">
                  <h6>
                    {{ data.User.Profile.first_name }}
                    {{ data.User.Profile.last_name }}
                  </h6>
                </router-link>
              </div>
            </div>

            <div class="row text-center py-3">
              <div class="col-12 col-md-6">
                <h4 class="text-gray">Comment</h4>
                <h6>{{ data.comment ? data.comment : "No Comment" }}</h6>
              </div>
              <div class="col-12 col-md-6">
                <h4 class="text-gray">Remark</h4>
                <h6>{{ data.remark ? data.remark : "No Remark" }}</h6>
              </div>
              <div class="col-12">
                <div class="card card-body border-light shadow-sm">
                  <div class="row">
                    <img class="col-12 col-md-6 col-lg-4" v-for="(image, index) in data.images_proof" :src="image"
                      :key="index" alt="Img" />
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="col-12 col-md-6 col-lg-4">
              <div class="card card-body border-light shadow-sm">
                <img
                  v-for="(image, index) in data.images_proof"
                  :src="image"
                  :key="index"
                  alt="Img"
                  class="img-fluid"
                />
              </div>
            </div>-->
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="card card-body border-light shadow-sm">
            <img v-for="(image, index) in data.images" :src="image" :key="index" alt="Img" />
          </div>
        </div>
        <div class="col-12 col-md-8">
          <div class="card card-body border-light shadow-sm">
            <div class="text-center">
              <h6 class="text-gray">Gift Card information</h6>
            </div>

            <div class="row text-center py-3">
              <div class="col-4">
                <h4 class="text-gray">UID</h4>
                <h6>{{ data.GiftCard.uid }}</h6>
              </div>
              <div class="col-4">
                <h4 class="text-gray">Name</h4>
                <h6>{{ data.GiftCard.name }}</h6>
              </div>
              <div class="col-4">
                <h4 class="text-gray">Preview</h4>
                <img :src="data.GiftCard.image" height="40" width="60" />
              </div>
            </div>

            <div class="text-center">
              <h6 class="text-gray">Gift Card Entry Details</h6>
            </div>

            <div class="row text-center py-3">
              <div class="col-4">
                <h4 class="text-gray">Range</h4>
                <h6>{{ data.GiftCardEntry.range }}</h6>
              </div>
              <div class="col-4">
                <h4 class="text-gray">Description</h4>
                <h6>{{ data.GiftCardEntry.description }}</h6>
              </div>
              <div class="col-4">
                <h4 class="text-gray">Rate</h4>
                <h6>{{ data.GiftCardEntry.rate }}</h6>
              </div>
            </div>
          </div>
        </div>
        <div v-if="data.AuthorisedBy" class="col-12">
          <div class="card card-body border-light shadow-sm">
            <div class="text-center">
              <h6 class="text-gray">Authorized By</h6>
            </div>

            <div class="row text-center py-3">
              <div class="col-4">
                <h4 class="text-gray">USERID</h4>
                <h6>{{ data.AuthorisedBy.id }}</h6>
              </div>
              <div class="col-4">
                <h4 class="text-gray">Name</h4>
                <h6>
                  {{ data.AuthorisedBy.Profile?.first_name }}
                  {{ data.AuthorisedBy.Profile?.last_name }}
                </h6>
              </div>
              <div class="col-4">
                <h4 class="text-gray">Username</h4>
                <h6>{{ data.AuthorisedBy.username }}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="row">
        <div class="col-12">
          <h1 class="text-danger text-center">No Data!</h1>
        </div>
      </div>
    </section>
    <div class="offcanvas offcanvas-end" tabindex="-1" id="approveCanvas" aria-labelledby="approveCanvasLabel">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="approveCanvasLabel">
          Approve Transaction
        </h5>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"
          ref="canvasClose"></button>
      </div>
      <div class="offcanvas-body">
        <DefaultLoader :loading="loading">
          <form @submit.prevent="ApproveTransaction">
            <div class="mb-3">
              <div>
                <label for="amount" class="form-label">Amount Payeable ({{ data?.CreditCurrency?.code }})</label>
                <InputWrapper>
                  <input type="number" class="form-control form-control-lg" id="amount" step="0.0001"
                    v-model="approvalData.amount" required />
                </InputWrapper>
              </div>
            </div>

            <div class="mb-3">
              <div class="form-floating">
                <textarea class="form-control form-control-lg" placeholder="Leave a comment here" id="remark"
                  v-model="approvalData.remark" style="height: 100px" required></textarea>
                <label for="remark">Remark</label>
              </div>
            </div>

            <div class="mb-3 text-center">
              <button class="btn btn-dark">Approve Now</button>
            </div>
          </form>
        </DefaultLoader>
      </div>
    </div>
  </HourglassLoader>

  <div class="card-withdrawal-response" v-if="sureConfirmDecline">
    <SecondaryModal :modalActive="sureConfirmDecline" @close="sureConfirmDecline = false">
      <div class style="width: 24rem">
        <div class="card-body d-flex justify-content-center align-items-center">
          <DefaultLoader :loading="loading">
            <div class>
              <h5 class="card-title my-3 text-center">Reason for Decline</h5>
              <!-- <button
                @click.prevent="customComment = !customComment"
                type="button"
                class="btn btn-secondary"
              >
              </button>-->
              <div>
                <span v-if="customComment">Custom Response</span>
                <span v-else>Automated Response</span>
              </div>

              <div class="mb-3 response-list">
                <div class v-if="customComment">
                  <div class="my-2">
                    <textarea class="form-control" placeholder="Leave a comment here" id="remark" v-model="declineComment"
                      style="height: 100px; min-width: 100%" required></textarea>
                    <!-- Add Image -->
                    <div class="my-1">
                      <div v-for="(image, index) in images" :key="index" class="mb-3 d-flex gap-2">
                        <input type="file" @change="handleImageChange($event, index)" class="form-control" />
                        <button class="btn btn-danger" @click.prevent="removeImage(index)">
                          <i class="fa fa-trash" aria-hidden="true"></i>
                        </button>
                      </div>
                      <button class="btn btn-secondary" @click="addImage">
                        <i class="fa fa-plus" aria-hidden="true"></i>
                        <span class="ms-2">Image</span>
                      </button>
                    </div>
                    <!-- <label for="remark">Remark</label> -->
                  </div>

                  <div class="d-flex justify-content-between">
                    <button @click="DeclineTransaction(declineComment)" type="button" class="btn btn-primary">
                      Submit Decline
                    </button>
                  </div>
                </div>
                <div v-else>
                  <div class="d-flex flex-column my-1" v-for="remark in automatedResponses" :key="remark.id">
                    <div class="decline-response text-white btn p-1" @click="DeclineTransaction(remark.response)">
                      {{ remark.response }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="d-flex align-items-center">
                <button @click="sureConfirmDecline = false" type="button" class="btn btn-light me-auto">
                  Cancel
                </button>
                <h6 class="cursor-pointer mx-4" @click.prevent="customComment = !customComment">
                  <span v-if="!customComment" class="text-underline text-info">Enter a custom response</span>
                  <span v-else class="text-underline text-info">Use Automated response</span>
                </h6>
              </div>
            </div>
          </DefaultLoader>
        </div>
      </div>
    </SecondaryModal>
  </div>
</template>

<script lang="ts">
import { apiGet, apiPost } from "@/api";
import InputWrapper from "@/components/InputWrapper.vue";
import DefaultLoader from "@/components/loader/DefaultLoader.vue";
import HourglassLoader from "@/components/loader/HourglassLoader.vue";
import SecondaryModal from "@/components/modal/SecondaryModal.vue";
import { Toast } from "@/utils/notify";
import {
  Ref,
  defineComponent,
  inject,
  onBeforeMount,
  ref,
  watchEffect,
} from "vue";
import { useRoute } from "vue-router";
export default defineComponent({
  name: "SellGiftCardDetail",
  components: {
    DefaultLoader,
    SecondaryModal,
    InputWrapper,
    HourglassLoader,
  },
  setup() {
    const loading = ref(false);
    const declineModal = ref(false);
    const declineModalConfirm = ref(false);
    const sureConfirmDecline = ref(false);
    const declineComment = ref("");
    const customComment = ref(false);
    const automatedResponses = ref([]);

    const images = ref<File[]>([]);
    const imageUrls = ref<string[]>([]);

    const handleImageChange = async (event: Event, index: number) => {
      console.log(imageUrls.value, "imageUrls");
      console.log(images.value, "images");

      const target = event.target as HTMLInputElement;
      const file = target.files?.[0];
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        // formData.append("upload_preset", "your_cloudinary_upload_preset");

        try {
          const response = await apiPost(
            `https://astroafrica-backend.onrender.com/v1/misc/upload-file`,
            formData
          );

          console.log(response, "response");

          // const data = await response;
          imageUrls.value[index] = response.data.data.publicUrl;

          console.log(imageUrls.value, "imageUrls");
        } catch (error) {
          console.error("Image upload failed:", error);
        }
      }
    };

    const removeImage = (index: number) => {
      console.log(index, "index");

      images.value.splice(index, 1);
      imageUrls.value.splice(index, 1);

      console.log(images.value, "images");
    };

    const addImage = () => {
      images.value.push(new File([], ""));
      imageUrls.value.push("");

      console.log(images.value, "images");
    };

    // const automatedResponses = [
    //   {
    //     id: 1,
    //     comment: "Numbers not showing full",
    //   },
    //   {
    //     id: 2,
    //     comment: "Card has been used, kindly contact your source",
    //   },
    //   {
    //     id: 3,
    //     comment: "Card numbers not clear",
    //   },
    //   {
    //     id: 4,
    //     comment: "Repeated trade, Card has been used by another customer",
    //   },
    //   {
    //     id: 5,
    //     comment: "Card is badly scratched, some codes are peeled off",
    //   },
    //   {
    //     id: 6,
    //     comment: "We do not accept this kinds of Card, thanks",
    //   },
    // ];

    const canvasClose: Ref<HTMLButtonElement | null> = ref(null);
    const data: Ref<Record<string, any> | null> = ref(null);

    const RequestConfirmation = inject("RequestConfirmation");

    const approvalData: Ref<{
      amount: null | number;
      remark: string;
    }> = ref({
      amount: null,
      remark: "Approved Successfully",
    });

    const route = useRoute();

    watchEffect(() => {
      document.body.classList.toggle(
        "no-scroll-on-decline-modal",
        sureConfirmDecline.value
      );
    });

    const RefreshData = async () => {
      loading.value = true;
      try {
        const response = await apiGet(
          `/admin/cards/sell/transactions/${route.params.transactionId}`
        );
        const getAutomatedResponses = await apiGet(
          `/admin/misc/automated/response`
        );

        automatedResponses.value =
          getAutomatedResponses.data.data.automatedResponse;

        // console.log(automatedResponses.value);

        data.value = response.data.data.transaction;
        // approvalData.value.amount =
        //   Number(response.data.data.transaction.amount) *
        //   Number(response.data.data.transaction.rate) *
        //   Number(response.data.data.transaction.CreditCurrency.we_buy);
        approvalData.value.amount = Number(
          response.data.data.transaction.payout_amount
        );

        approvalData.value.remark = response.data.data.transaction.remark;
        Toast.fire({
          icon: "success",
          title: "Transaction Detail Fetched Successfully",
        }).then();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch data: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    const ApproveTransaction = async () => {
      loading.value = true;
      const amount =
        Number(approvalData.value.amount) /
        Number(data.value?.rate) /
        Number(data.value?.CreditCurrency.we_buy);
      try {
        await apiPost(
          `/admin/cards/sell/transactions/${route.params.transactionId}/approve`,
          { ...approvalData.value, amount }
        );
        Toast.fire({
          icon: "success",
          title: "Transaction Approved Successfully",
        }).then();
        canvasClose.value?.click();
        await RefreshData();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to approve: " + e.message,
        }).then();
        loading.value = false;
      }
    };

    // add Decline options
    // const addOption = (arr: string[]): string => {
    //   let optionItems = "";
    //   // const optionWrapper = () => {
    //   //   return `<div class="declineOptionWrapper">

    //   //     </div>`;
    //   // };
    //   arr.forEach((item) => {
    //     optionItems += `<button class="d-block my-2 mx-auto" value="${item}">${item}</button>`;
    //   });
    //   return optionItems;
    // };

    // title: "Decline Transaction",
    //     inputLabel: "Enter Reason/Comment for declining",
    //     html: addOption([
    //       "invalid image",
    //       "invalid amount",
    //       "invalid card",
    //       "other",
    //     ]),

    const DeclineTransaction = async (comment: string) => {
      // const { value: text } = await Swal.fire({

      //   input: "textarea",
      //   inputLabel: "Enter Reason/Comment for declining",
      //   inputPlaceholder: "Type your reason/comment here...",
      //   inputAttributes: {
      //     "aria-label": "Type your reason/comment here",
      //   },
      //   showCancelButton: true,
      // });

      // if (!text) {
      //   return;
      // }

      // if (!declineComment.value) {
      //   return;
      // }

      loading.value = true;
      try {
        await apiPost(
          `/admin/cards/sell/transactions/${route.params.transactionId}/decline`,
          { comments: comment, imagesProof: imageUrls.value }
        );

        console.log(comment, imageUrls.value);

        sureConfirmDecline.value = false;
        Toast.fire({
          icon: "success",
          title: "Transaction Declined Successfully",
        }).then();

        await RefreshData();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to decline: " + e.message,
        }).then();
        loading.value = false;
      }
    };

    onBeforeMount(RefreshData);

    return {
      RefreshData,
      ApproveTransaction,
      DeclineTransaction,

      images,
      handleImageChange,
      removeImage,
      addImage,

      automatedResponses,
      RequestConfirmation,
      customComment,
      approvalData,
      route,
      data,
      loading,
      declineModal,
      declineModalConfirm,
      sureConfirmDecline,
      declineComment,
      canvasClose,
    };
  },
});
</script>

<style>
.card-withdrawal-response .question-border {
  border: 2px solid #f1c5f9;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  padding: 2rem;
}

.card-withdrawal-response .decline-response {
  background-color: rgb(95, 116, 54);
}

.card-withdrawal-response .response-list {
  height: 12rem !important;
  overflow-y: auto;
}

.card-withdrawal-response .cursor-pointer {
  cursor: pointer;
}

.no-scroll-on-decline-modal {
  overflow: hidden;
}
</style>
